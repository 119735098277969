import * as React from "react"
import { navigate } from "gatsby"
import {
  FaSearchengin,
  FaGlobe,
  FaMobileAlt,
  FaSellsy,
  FaClipboardList,
  FaFileContract,
  FaDraftingCompass,
  FaCode,
  FaStethoscope,
  FaLaptopCode,
  FaSnowman,
  FaCloudUploadAlt,
  FaHtml5,
  FaCss3Alt,
  FaSass,
  FaBootstrap,
  FaMdb,
  FaJsSquare,
  FaReact,
  FaVuejs,
  FaAngular,
  FaNodeJs,
  FaAws,
  FaLaravel,
  FaPhp,
  FaApple,
  FaArrowAltCircleDown,
  FaAngleDoubleRight,
} from "react-icons/fa"
import {
  DiGoogleAnalytics,
  DiAndroid,
  DiChrome,
  DiMysql,
  DiMsqlServer,
  DiDatabase,
  DiPhotoshop,
  DiIllustrator,
  DiDreamweaver,
  DiVisualstudio,
  DiJava,
} from "react-icons/di"
import { GiClick } from "react-icons/gi"
import { SiBrandfolder } from "react-icons/si"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import SVGComponent from "../images/svg/design-development.svg"
// import ReactTypingEffect from "react-typing-effect"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Custom Website Development Company in Delaware"
      description="Based in Wilmington, Delaware we build Responsive Web Design, Brand Identity, Brand Strategy, SEO, Ecommerce, and mobile Apps which transforms your business"
      keyword="responsive web design, Branding, e-commerce, UI/UX, User Experience, User Interface, SEO, Search Engine Optimization, Mobile App"
    />

    <Container fluid className="min-vh-100">
      <br /><br />
    <Row className="text-center mt-5">
      <Col>
    <h1 className="text3d fs-3 mb-5 ">CUSTOM WEBSITE DEVELOPMENT COMPANY</h1>
    </Col>
    </Row>
      {/* <div className="text-center text-white fs-4 typewriter">
        <ReactTypingEffect speed={50} eraseSpeed={50} typingDelay={100} eraseDelay={100}
          text={[
            "We build attractive websites",
            "Stunning Mobile Apps",
            "e-commerce Applications",
            "Search Engine Optimization",
            "UI/UX Designing",
            "And Branding",
            "using top noch technologies",
          ]}
        />
      </div> */}
      <Row><Col>
      <SVGComponent width="100%" />
      </Col></Row>
      <Row className="text-center text-white d-block d-md-none mt-5">
          <Col><br /><br /><br />      <br /> <br />
            <h2 className="h2 luminance">
              We Make Innovation Beyond Imagination!
              {/* Websites built from scratch! Which are Unique, Fast, Secure and Scalable! */}
            </h2>
          </Col>
        </Row>
        <br />
        <div className="position-absolute bottom-0 start-50 text-white mb-1 d-block d-md-none">
          <FaArrowAltCircleDown className="bouncearrow" />
        </div>
    </Container>
    <Container fluid className="bg-white">
      <br /> <br /> <br />
      <br />
      <Container className="text-center w-75 ms-auto me-auto bg-text mt-4 mb-5">
        <Card.Body>
          <Card.Title className="mt-1">
          <h6
            className="text-center fs-2 bg-textdark"
            data-aos="flip-up"
          >
            ONE-STOP TO FIT ALL YOUR NEEDS!
          </h6>
            <br /><br />
          </Card.Title>
          <Card.Text className=" text-center mt-3 fs-4">
            We specialize in custom website development to improve
            our client’s online footprint by creating better user experiences.
            {/* <h1 className="blockquote">We offer custom web design & development services – including branding, ecommerce,
            mobile application development, Search Engine Optimization, and
            enhanced UI/UX.</h1> */}
            <br />
            <br />
            We are here to provide exceptional quality service, Our success is
            our customers satisfaction. we believe in relationships of lifetime with
            our experience, dedication, hard work and quality of service. We put
            customer first and always stand behind them throughout the journey
            of their success. We take full responsibility of our customers
            online identity and provide best in-class support to help them
            through the difficulties come along the way of their success.
          </Card.Text>
        </Card.Body>
      </Container>
      <br />
      <br />
      <br />
      <br />
    </Container>
    <Container fluid>
      <br />
            <br />
      <h1 className="text-center bg-textlight">
        OUR SERVICES
      </h1>
      <Row className="justify-content-evenly mt-5 me-3 ms-3">
        <Col lg={4} className="mt-4">
          <Card id="cardLinks" 
            className="text-center border-2"
            data-aos="fade-in"
            onClick={() => {
              navigate("/Web-Design")
            }}
          >
            <FaGlobe className="fa-globe text-secondary text-center ms-auto me-auto mt-2" />
            <Card.Body>
              <Card.Title>
                <h2 className="fs-4">Website Design</h2>
              </Card.Title>
              <Card.Text>
                We provide custom web design with proven strategies for
                conversions and Search Engine Optimization (SEO). We will work
                closely with you to define, design and develop transformative
                user experiences across mobile, web and tablet platforms. Our
                web developers create user-friendly web solutions that are
                responsive, secured, and scalable to meet all business needs.<br /><br /><span id="pchangecolor">Learn More <FaAngleDoubleRight /></span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="mt-4">
          <Card id="cardLinks" 
            className="text-center"
            data-aos="fade-in"
            onClick={() => {
              navigate("/Mobile-app")
            }}
          >
            <FaMobileAlt className="fa-mobile-alt text-secondary text-center ms-auto me-auto mt-2" />
            <Card.Body>
              <Card.Title>
                <h2 className="fs-4">Mobile Application</h2>
              </Card.Title>
              <Card.Text>
                Our team has extensive experience building mobile apps in terms
                of app design, wireframe, functional document, User
                Interface/User Experience (UI/UX), programming, testing, and
                deployment. We have perfected apps for both Android and iOS
                platforms. Our mobile app development team ensures that you get
                the world-class app, which acts as a catalyst for success.<br /><br /><span id="pchangecolor">Learn More <FaAngleDoubleRight /></span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="mt-4">
          <Card id="cardLinks" 
            className="text-center"
            data-aos="fade-in"
            onClick={() => {
              navigate("/ecommerce")
            }}
          >
            <FaSellsy className="fa-chart-line text-secondary text-center ms-auto me-auto mt-2" />
            <Card.Body>
              <Card.Title>
                <h2 className="fs-4">e-Commerce</h2>
              </Card.Title>
              <Card.Text>
                We provide e-commerce development services which
                serves as the foundation of your online business. Our team
                utilizes latest technology to maximize profitability of your
                online commerce. Our e-commerce solutions offer best-in-class
                features and functionality while remaining agile and completely
                customizable. Partner with us to achieve your goals.<br /><br /><span id="pchangecolor">Learn More <FaAngleDoubleRight /></span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-evenly mb-5 me-3 ms-3">
        <Col lg={4} className="mt-4">
          <Card id="cardLinks" 
            className="text-center"
            data-aos="fade-in"
            onClick={() => {
              navigate("/seo")
            }}
          >
            <FaSearchengin className="fa-globe text-secondary text-center ms-auto me-auto mt-2" />
            <Card.Body>
              <Card.Title>
                <h2 className="fs-4">Search Engine Optimization</h2>
              </Card.Title>
              <Card.Text>
                Outrank your competition and capture market share. SoftCode SEO
                services offers a comprehensive approach to increase your
                website rankings on Google, Yahoo, Bing and more. Our team uses
                advanced algorithm by optimizing on-page performance, resolving
                backend issues, and developing an SEO friendly content strategy.<br /><br /><span id="pchangecolor">Learn More <FaAngleDoubleRight /></span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="mt-4">
          <Card id="cardLinks" 
            className="text-center"
            data-aos="fade-in"
            onClick={() => {
              navigate("/uiux")
            }}
          >
            <GiClick className="fa-globe text-secondary text-center ms-auto me-auto mt-2" />
            <Card.Body>
              <Card.Title>
                <h2 className="fs-4">UI/UX Design</h2>
              </Card.Title>
              <Card.Text>
                At SoftCode, we work closely with you to define, design, and
                develop transformative user experiences across all platforms and
                brand’s touchpoints. We optimize UI/UX design, color, and fonts
                to create a customized experience for you, establish a simple
                and intuitive content hierarchy and enhance the overall brand
                aesthetic.<br /><br /><span id="pchangecolor">Learn More <FaAngleDoubleRight /></span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="mt-4">
          <Card id="cardLinks" 
            className="text-center"
            data-aos="fade-in"
            onClick={() => {
              navigate("/branding")
            }}
          >
            <SiBrandfolder className="fa-globe text-secondary text-center ms-auto me-auto mt-2" />
            <Card.Body>
              <Card.Title>
                <h2 className="fs-4">Branding</h2>
              </Card.Title>
              <Card.Text>
                At SoftCode, we create visual identity and develop brand
                strategies for you. Our team has developed an innovative
                approach that results in professional, stand-out brand designs
                which builds trust. We find what makes you extraordinary and
                tell the world through building brand experiences that help you
                flex, scale and transform.<br /><br /><span id="pchangecolor">Learn More <FaAngleDoubleRight /></span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
    <Container fluid className="bg-white">
      <br />
      <Container>
        <h3
          className="text-center bg-textdark"
          data-aos="flip-up"
        >
          HOW WE WORK
        </h3>
        <br />
        <Row>
          <Col md={12}>
            <div className="main-timeline">
              <div className="timeline" data-aos="fade-up">
                <div className="timeline-icon">
                  <span className="year">
                    <FaClipboardList className="timelinesvg-shadow" />
                  </span>
                </div>
                <div className="timeline-content">
                  <h3 className="title">Requirement Gathering</h3>
                  <p className="description">
                    This is our exploratory process of researching,
                    understanding and documenting customer requirements to meet
                    expectations.
                  </p>
                </div>
              </div>
              <div className="timeline" data-aos="fade-up">
                <div className="timeline-icon">
                  <span className="year">
                    <FaFileContract className="timelinesvg-shadow" />
                  </span>
                </div>
                <div className="timeline-content">
                  <h3 className="title">Cost & Time Estimation</h3>
                  <p className="description">
                    We compile all the business requirements and add any
                    additional services customer may need and provide an initial
                    estimate.
                  </p>
                </div>
              </div>
              <div className="timeline" data-aos="fade-up">
                <div className="timeline-icon">
                  <span className="year">
                    <FaDraftingCompass className="timelinesvg-shadow" />
                  </span>
                </div>
                <div className="timeline-content">
                  <h3 className="title">Project Planning</h3>
                  <p className="description">
                    We track, manage resources, project status and costs with
                    comprehensive project plans and focus on maximizing value
                    and deliver high (ROI)
                  </p>
                </div>
              </div>
              <div className="timeline" data-aos="fade-up">
                <div className="timeline-icon">
                  <span className="year">
                    <FaCode className="timelinesvg-shadow" />
                  </span>
                </div>
                <div className="timeline-content">
                  <h3 className="title">Design & Development</h3>
                  <p className="description">
                    Our team will brainstorm and come up with preliminary
                    designs. We will build the framework and finalize the
                    designs based on previous step.
                  </p>
                </div>
              </div>
              <div className="timeline" data-aos="fade-up">
                <div className="timeline-icon">
                  <span className="year">
                    <FaStethoscope className="timelinesvg-shadow" />
                  </span>
                </div>
                <div className="timeline-content">
                  <h3 className="title">Testing</h3>
                  <p className="description">
                    Our Quality Assurance team will test the functionality of
                    the product using various methods to ensure quality of our
                    creation.
                  </p>
                </div>
              </div>
              <div className="timeline" data-aos="fade-up">
                <div className="timeline-icon">
                  <span className="year">
                    <FaLaptopCode className="timelinesvg-shadow" />
                  </span>
                </div>
                <div className="timeline-content">
                  <h3 className="title">Demonstration & Feedback</h3>
                  <p className="description">
                    We will arrange a demo with the customer to showcase our
                    work. Based on customer feedback we will update the website
                    accordingly.
                  </p>
                </div>
              </div>
              <div className="timeline" data-aos="fade-up">
                <div className="timeline-icon">
                  <span className="year">
                    <FaSnowman className="timelinesvg-shadow" />
                  </span>
                </div>
                <div className="timeline-content">
                  <h3 className="title">Final Demonstration</h3>
                  <p className="description">
                    Once all the changes are implemented as per the feedback,
                    then a final demo with the customer will be scheduled.
                  </p>
                </div>
              </div>
              <div className="timeline" data-aos="fade-up">
                <div className="timeline-icon">
                  <span className="year">
                    <FaCloudUploadAlt className="timelinesvg-shadow" />
                  </span>
                </div>
                <div className="timeline-content">
                  <h3 className="title">Go Live & Verification</h3>
                  <p className="description">
                    Once the customer is happy with the product, we will hand
                    over the website to our customer and make it live for the
                    entire world to see.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <br />
    </Container>
    <Container fluid>
      <br />
      <Container>
        <h3 className="text-center text-white bg-textlight">WHAT WE USE</h3>
        <br />
        <br />
        <Row className="row-cols-sm-5 text-center align-middle text-white fs-1 justify-content-evenly">
          <Col>
            <FaHtml5 className="wwu-icons" />
            <p className="fs-6">
              <small>HTML</small>
            </p>
          </Col>
          <Col>
            <FaCss3Alt className="wwu-icons" />
            <p className="fs-6">
              <small>CSS</small>
            </p>
          </Col>
          <Col>
            <FaSass className="wwu-icons" />
            <p className="fs-6">
              <small>SASS</small>
            </p>
          </Col>
          <Col>
            <FaBootstrap className="wwu-icons" />
            <p className="fs-6">
              <small>Bootstrap</small>
            </p>
          </Col>
          <Col>
            <FaMdb className="wwu-icons" />
            <p className="fs-6">
              <small>MDB</small>
            </p>
          </Col>
          <Col>
            <FaJsSquare className="wwu-icons" />
            <p className="fs-6">
              <small>JavaScript</small>
            </p>
          </Col>
          <Col>
            <FaReact className="wwu-icons" />
            <p className="fs-6">
              <small>React</small>
            </p>
          </Col>
          <Col>
            <FaVuejs className="wwu-icons" />
            <p className="fs-6">
              <small>Vuejs</small>
            </p>
          </Col>
          <Col>
            <FaAngular className="wwu-icons" />
            <p className="fs-6">
              <small>Angular</small>
            </p>
          </Col>
          <Col>
            <FaNodeJs className="wwu-icons" />
            <p className="fs-6">
              <small>NodeJs</small>
            </p>
          </Col>
          <Col>
            <FaAws className="wwu-icons" />
            <p className="fs-6">
              <small>AWS</small>
            </p>
          </Col>
          <Col>
            <FaLaravel className="wwu-icons" />
            <p className="fs-6">
              <small>Laravel</small>
            </p>
          </Col>
          <Col>
            <FaPhp className="wwu-icons" />
            <p className="fs-6">
              <small>PHP</small>
            </p>
          </Col>
          <Col>
            <DiJava className="wwu-icons" />
            <p className="fs-6">
              <small>Java</small>
            </p>
          </Col>
          <Col>
            <FaApple className="wwu-icons" />
            <p className="fs-6">
              <small>iOS</small>
            </p>
          </Col>
          <Col>
            <DiAndroid className="wwu-icons" />
            <p className="fs-6">
              <small>Android</small>
            </p>
          </Col>
          <Col>
            <DiGoogleAnalytics className="wwu-icons" />
            <p className="fs-6">
              <small>Analytics</small>
            </p>
          </Col>
          <Col>
            <DiChrome className="wwu-icons" />
            <p className="fs-6">
              <small>Chrome</small>
            </p>
          </Col>
          <Col>
            <DiMysql className="wwu-icons" />
            <p className="fs-6">
              <small>MySQL</small>
            </p>
          </Col>
          <Col>
            <DiMsqlServer className="wwu-icons" />
            <p className="fs-6">
              <small>MS&nbsp;SQL</small>
            </p>
          </Col>
          <Col>
            <DiDatabase className="wwu-icons" />
            <p className="fs-6">
              <small>Oracle</small>
            </p>
          </Col>
          <Col>
            <DiPhotoshop className="wwu-icons" />
            <p className="fs-6">
              <small>Photoshop</small>
            </p>
          </Col>
          <Col>
            <DiIllustrator className="wwu-icons" />
            <p className="fs-6">
              <small>Illustrator</small>
            </p>
          </Col>
          <Col>
            <DiDreamweaver className="wwu-icons" />
            <p className="fs-6">
              <small>Dreamweaver</small>
            </p>
          </Col>
          <Col>
            <DiVisualstudio className="wwu-icons" />
            <p className="fs-6">
              <small>Visual&nbsp;Studio</small>
            </p>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <br />
    </Container>
  </Layout>
)

export default IndexPage
